import React from "react";
import { Input, Row, Col } from "antd";

export function ExpiresAtInput({ value, onChange }) {
  const stringValue =
    value === null || value === undefined || value === -1 ? "" : String(value);

  const handleChange = e => {
    const raw = e.target.value;

    if (raw === "") {
      if (onChange) onChange(null);
      return;
    }

    const val = parseInt(raw, 10);
    if (onChange) onChange(isNaN(val) ? 0 : val);
  };

  return (
    <Input.Group>
      <Row gutter={10}>
        <Col span={24}>
          <Input
            placeholder="No caducar"
            className="responsive-input"
            autoComplete="cc-csc"
            value={stringValue}
            type="number"
            onChange={handleChange}
          />
        </Col>
      </Row>
    </Input.Group>
  );
}
